import { PageLink, PageTitle } from "../../../../_metronic/layout/core"
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from "react-router-dom"
import { Liste } from "./components/ndf/Liste"
import { DeclarationListWrapper } from "./components/declaration/DeclarationList"
import { DeclarationCreateWrapper } from "./components/declaration/declarationCreate/DeclarationCreate"
import { DeclarationUpdate } from "./components/declaration/declarationUpdate/DeclarationUpdate"
import { NdfCreate } from "./components/ndf/NdfCreate/NdfCreate"
import { NdfDetailsWrapper } from "./components/ndf/NdfDetails/NdfDetailsWrapper"
import { DdcWrapper } from "./components/ddc/DdcListe"
import { CraList } from "./components/cra/CraList"
import { CraCreate } from "./components/cra/craCreate/CraCreate"
import { CraUpdate } from "./components/cra/craUpdate/CraUpdate"
import { MaladieWrapper } from "./components/maladie/MaladieListe"
import { useAuth } from "../../../modules/auth"
import { CONST_CONSULTANT_CONTRAT_ST, CONST_CONSULTANT_STATUT_ST_FREELANCE } from "../../../constants"
import { FirstLetterUppercase } from "../../../helpers/helper-global"
import moment from "moment"

const urlParams = new URLSearchParams(window.location.search)

export const ActivitesPage = () => {
     const navigate = useNavigate()
     const { currentUser } = useAuth()
     const location_state = useLocation().state as { breadCrumbPageTitle: string }
     const breadCrumbPageTitle = location_state?.breadCrumbPageTitle || (urlParams.get("breadCrumbPageTitle") as string)

     return (
          <Routes>
               <Route
                    element={
                         <>
                              <Outlet />
                         </>
                    }
               >
                    {currentUser?.consultant?.consultant && (
                         <Route path={"declarations"}>
                              <Route
                                   path=""
                                   element={
                                        <>
                                             <PageTitle breadcrumbs={declarationsBreadCrumbs}>Déclarations</PageTitle>
                                             <DeclarationListWrapper
                                                  consultant_ID={currentUser?.consultant?.consultant?.id!}
                                                  onClickOnConsultationButtonCallback={(declaration, defaultStep) => {
                                                       navigate("/activites/declarations/update", {
                                                            state: {
                                                                 declaration_id: declaration.id,
                                                                 default_step: defaultStep,
                                                                 breadCrumbPageTitle: FirstLetterUppercase(moment(declaration.mois).format("MMMM YYYY")),
                                                            },
                                                       })
                                                  }}
                                             />
                                        </>
                                   }
                              />

                              <Route
                                   path="new"
                                   element={
                                        <>
                                             <PageTitle breadcrumbs={declarationsBreadCrumbs}>Ajouter</PageTitle>
                                             <DeclarationCreateWrapper
                                                  consultant_ID={currentUser?.consultant?.consultant?.id as number}
                                                  onSubmitDeclarationExistsCallback={(existentDeclaration, selectedMonth) => {
                                                       navigate(`/activites/declarations/update`, {
                                                            state: {
                                                                 declaration_id: existentDeclaration.id,
                                                                 breadCrumbPageTitle: `${FirstLetterUppercase(selectedMonth.format("MMMM YYYY"))}`,
                                                                 default_step:
                                                                      currentUser?.consultant?.consultant?.statut === CONST_CONSULTANT_STATUT_ST_FREELANCE ? "cra" : "arrets",
                                                            },
                                                       })
                                                  }}
                                                  onSubmitSuccessForCreationCallback={(id: number, selectedMonth) => {
                                                       navigate(`/activites/declarations/update`, {
                                                            state: {
                                                                 declaration_id: id,
                                                                 breadCrumbPageTitle: `${FirstLetterUppercase(selectedMonth.format("MMMM YYYY"))}`,
                                                                 default_step:
                                                                      currentUser?.consultant?.consultant?.statut === CONST_CONSULTANT_STATUT_ST_FREELANCE ? "cra" : "arrets",
                                                            },
                                                       })
                                                  }}
                                                  onClickPreviousCallback={() => navigate("/activites/declarations")}
                                             />
                                        </>
                                   }
                              />

                              <Route
                                   path="update"
                                   element={
                                        <>
                                             <DeclarationUpdate onClickOnRetourAuxDeclarationCallback={() => navigate("/activites/declarations")} />
                                        </>
                                   }
                              />
                         </Route>
                    )}

                    <Route path={"cra"}>
                         <Route
                              path=""
                              element={
                                   <>
                                        <PageTitle breadcrumbs={craBreadCrumbs} />
                                        <CraList />
                                   </>
                              }
                         />

                         <Route
                              path="new"
                              element={
                                   <>
                                        <PageTitle breadcrumbs={craBreadCrumbs}>Nouveau</PageTitle>
                                        <CraCreate />
                                   </>
                              }
                         />

                         <Route
                              path="update"
                              element={
                                   <>
                                        <PageTitle breadcrumbs={craBreadCrumbs}>{breadCrumbPageTitle}</PageTitle>
                                        <CraUpdate />
                                   </>
                              }
                         />
                    </Route>

                    <Route path={"ndf"}>
                         <Route
                              path=""
                              element={
                                   <>
                                        <PageTitle breadcrumbs={ndfBreadCrumbs}>Liste</PageTitle>
                                        <Liste />
                                   </>
                              }
                         />

                         <Route
                              path="new"
                              element={
                                   <>
                                        <PageTitle breadcrumbs={ndfBreadCrumbs}>Nouvelle note de frais</PageTitle>
                                        <NdfCreate consultant_ID={currentUser?.consultant?.consultant?.id!} />
                                   </>
                              }
                         />

                         <Route
                              path="update"
                              element={
                                   <>
                                        <PageTitle breadcrumbs={ndfBreadCrumbs}>{breadCrumbPageTitle}</PageTitle>
                                        <NdfDetailsWrapper />
                                   </>
                              }
                         />
                    </Route>

                    {currentUser?.consultant?.consultant?.contrat != CONST_CONSULTANT_CONTRAT_ST && (
                         <>
                              <Route path={"ddc"}>
                                   <Route
                                        path=""
                                        element={
                                             <>
                                                  <PageTitle breadcrumbs={ddcBreadCrumbs} />
                                                  <DdcWrapper />
                                             </>
                                        }
                                   />
                              </Route>

                              <Route path={"maladie"}>
                                   <Route
                                        path=""
                                        element={
                                             <>
                                                  <PageTitle breadcrumbs={maladieBreadCrumbs} />
                                                  <MaladieWrapper />
                                             </>
                                        }
                                   />
                              </Route>
                         </>
                    )}

                    <Route index element={<Navigate to="declarations/list" />} />
               </Route>
          </Routes>
     )
}

const activiteBreadCrumbs: Array<PageLink> = [
     {
          title: "Activités",
          path: "#",
     },
]

const declarationsBreadCrumbs: Array<PageLink> = [...activiteBreadCrumbs]

const craBreadCrumbs: Array<PageLink> = [
     ...activiteBreadCrumbs,
     {
          title: "Comptes rendus d'activité",
          path: "/activites/cra",
     },
]
const ndfBreadCrumbs: Array<PageLink> = [
     ...activiteBreadCrumbs,
     {
          title: "Notes de frais",
          path: "/activites/ndf",
     },
]
const ddcBreadCrumbs: Array<PageLink> = [
     ...activiteBreadCrumbs,
     {
          title: "Demandes de congés",
          path: "/activites/ddc",
     },
]

const maladieBreadCrumbs: Array<PageLink> = [
     ...activiteBreadCrumbs,
     {
          title: "Arrêts maladie",
          path: "/activites/maladie",
     },
]

export default ActivitesPage
