import React, { useMemo, useState } from "react"
import { DdcListingFilters } from "../../core/_models"
import MyTooltip from "../../../../../utils/MyTooltip"
import { useQuery, useQueryClient } from "react-query"
import { ConsultantArretMaladieModel, IHttpErrorResponseModel } from "../../../../../models"
import { AxiosError } from "axios"
import { maladieAnnulerRequest, maladieCreate, maladieEditRequest, maladieGetListe } from "../../core/_request"
import MyAlert from "../../../../../utils/MyAlert"
import { FirstLetterUppercase } from "../../../../../helpers/helper-global"
import Swal from "sweetalert2"
import withReactContent from "sweetalert2-react-content"
import * as Yup from "yup"
import MyModal from "../../../../../utils/MyModal"
import { ErrorMessage, Form, Formik, FormikHelpers } from "formik"
import { toast } from "react-toastify"
import moment from "moment"
import MyDateDayMonthYearField from "../../../../../utils/fields/MyDateDayMonthYearField"
import { downloadFile } from "../../../../../requests"
import MyCheckBoxField from "../../../../../utils/fields/MyCheckBoxField"
import { IEditArretMaladieEditRequestModel } from "./core/_models"
import { CONST_CIVILITE_MME, CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR } from "../../../../../constants"
import { useNavigate } from "react-router-dom"
import { useAuth } from "../../../../../modules/auth"
import FileAndDriveHelper from "../../../../../helpers/FileAndDriveHelper"

export function MaladieListe({ filtres, editionMode = true, maxHeight = "55vh" }: { filtres?: DdcListingFilters; editionMode?: boolean; maxHeight?: string }) {
     const queryClient = useQueryClient()
     const MySwal = withReactContent(Swal)
     const navigate = useNavigate()
     const { currentUser } = useAuth()

     const [isModalAddDdcShown, setIsModalAddDdcShown] = useState<boolean>(false)
     const [editArretInModal, setEditArretInModal] = useState<ConsultantArretMaladieModel>()

     const getMaladieListingQuery = useQuery<ConsultantArretMaladieModel[], AxiosError>("maladieListe", () => maladieGetListe(filtres).then(r => r.data))

     const FormAddArretMaladieInModal = useMemo(() => {
          const schema = Yup.object().shape({
               du: Yup.string().required("Champ requis"),
               au: Yup.string().required("Champ requis"),
               justificatif: Yup.mixed()
                    .required("Justificatif requis")
                    .test("fileSize", "La taille ne doit pas dépasser 10MB", (value: any) => {
                         return value && value.size <= 10000000
                    })
                    .test("fileFormat", "Format requis: JPEG, PNG ou PDF", (value: any) => {
                         return value && ["application/pdf", "image/jpeg", "image/png"].includes(value.type)
                    }),
          })

          return (
               <MyModal title={<span>Ajouter mon arrêt</span>} show={isModalAddDdcShown} handleClose={() => setIsModalAddDdcShown(false)} size={"lg"}>
                    <Formik
                         initialValues={{
                              du: "",
                              au: "",
                              justificatif: new File([new Blob()], ""),
                         }}
                         onSubmit={(values, { setStatus, setSubmitting }) => {
                              setStatus(null)
                              FileAndDriveHelper.processFiles([values.justificatif]).then(processedFiles => {
                                   maladieCreate({
                                        du: moment(values.du, "DD/MM/YYYY"),
                                        au: moment(values.au, "DD/MM/YYYY"),
                                        justificatif: processedFiles[0],
                                   })
                                        .then(r => {
                                             queryClient.setQueryData("maladieListe", (items: ConsultantArretMaladieModel[] | undefined) => {
                                                  if (items) {
                                                       return [r.data, ...items]
                                                  }
                                                  return []
                                             })

                                             toast.success("Votre arrêt maladie a bien été enregistré.")
                                             toast.info(
                                                  "Merci de vérifier, lors de l'étape de Compte Rendu d'Activité (CRA), si les heures d'absence liées à la maladie ainsi que celles de travail pour la période concernée par votre demande sont corrects.",
                                                  {
                                                       autoClose: false,
                                                  }
                                             )
                                             setSubmitting(false)
                                             setIsModalAddDdcShown(false)
                                        })
                                        .catch(e => {
                                             setStatus(e.response?.data?.detail)
                                             setSubmitting(false)
                                        })
                              })
                         }}
                         validationSchema={schema}
                    >
                         {({ values, setFieldValue, isSubmitting, status, errors }) => {
                              return (
                                   <Form noValidate autoComplete="off">
                                        {status && (
                                             <MyAlert type={"danger"} classNames={"mb-4"}>
                                                  {status}
                                             </MyAlert>
                                        )}

                                        <div className="row pb-4">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6 required">Saisissez la période</label>

                                             <div className="col-lg-8">
                                                  <div className="row">
                                                       <div className="col">
                                                            <MyDateDayMonthYearField
                                                                 onChange={date => setFieldValue("du", date)}
                                                                 minDate={
                                                                      filtres?.duOrAuWithThisMonthAndYear.startOf("month").format("DD/MM/YYYY") ||
                                                                      filtres?.du.startOf("month").format("DD/MM/YYYY")
                                                                 }
                                                                 maxDate={
                                                                      filtres?.duOrAuWithThisMonthAndYear.endOf("month").format("DD/MM/YYYY") ||
                                                                      filtres?.au.endOf("month").format("DD/MM/YYYY")
                                                                 }
                                                                 value={values.du}
                                                                 name={"du"}
                                                                 placeholder={"Du (inclus)"}
                                                            />
                                                            <ErrorMessage name={`du`}>{msg => <div className={"text-danger"}>{msg}</div>}</ErrorMessage>
                                                       </div>
                                                       <div className="col">
                                                            <MyDateDayMonthYearField
                                                                 onChange={date => setFieldValue("au", date)}
                                                                 value={values.au}
                                                                 minDate={values.du}
                                                                 name={"au"}
                                                                 placeholder={"Au (inclus)"}
                                                            />
                                                            <ErrorMessage name={`au`}>{msg => <div className={"text-danger"}>{msg}</div>}</ErrorMessage>
                                                       </div>
                                                  </div>
                                             </div>
                                        </div>

                                        <div className="row pb-4">
                                             <label className="col-lg-4 col-form-label fw-bold fs-6 required">Justificatif</label>

                                             <div className="col-lg-8">
                                                  <input
                                                       type={"file"}
                                                       name={`justificatif`}
                                                       onChange={e => {
                                                            setFieldValue("justificatif", e.target.files![0])
                                                       }}
                                                       className={"form-control"}
                                                  />
                                                  <ErrorMessage name={`justificatif`}>{msg => <div className={"text-danger"}>{msg}</div>}</ErrorMessage>
                                             </div>
                                        </div>

                                        <div className="text-center pt-4 d-flex flex-row-reverse">
                                             <button className={"btn btn-primary"} type={"submit"} disabled={isSubmitting}>
                                                  {!isSubmitting && (
                                                       <>
                                                            Valider <span className="fas fa-check-circle align-middle ms-1"></span>
                                                       </>
                                                  )}
                                                  {isSubmitting && (
                                                       <span className="indicator-progress" style={{ display: "block" }}>
                                                            Envoi en cours ...
                                                            <span className="spinner-border spinner-border-sm align-middle ms-1"></span>
                                                       </span>
                                                  )}
                                             </button>
                                        </div>
                                   </Form>
                              )
                         }}
                    </Formik>
               </MyModal>
          )
     }, [isModalAddDdcShown])

     const FormEditArretMaladieInModal = useMemo(() => {
          // schema
          const schema = Yup.object().shape({
               du: Yup.string().required("Champ requis"),
               au: Yup.string().required("Champ requis"),
               isJustificatifReplaced: Yup.boolean().required("Champ requis"),
               justificatif: Yup.mixed().when("isJustificatifReplaced", {
                    is: true,
                    then: schema => schema.required("Le justificatif est requis").typeError("Le justificatif est requis"),
               }),
          })

          const initialValues: IEditArretMaladieEditRequestModel = {
               du: editArretInModal?.du.format("DD/MM/YYYY"),
               au: editArretInModal?.au.format("DD/MM/YYYY"),
               isJustificatifReplaced: false,
          }

          // handle submit
          function handleSubmit(values: IEditArretMaladieEditRequestModel, helpers: FormikHelpers<IEditArretMaladieEditRequestModel>) {
               helpers.setStatus(null)
               maladieEditRequest(editArretInModal!.id, values)
                    .then(r => {
                         queryClient.setQueryData("maladieListe", (items: ConsultantArretMaladieModel[] | undefined) => {
                              if (items) {
                                   items[items.findIndex(item => item.id == editArretInModal?.id)] = r.data

                                   return items
                              }
                              return []
                         })

                         toast.success("Votre arrêt maladie a bien été édité.")
                         toast.info(
                              "Veuillez vérifier, dans votre Compte Rendu d'Activité (CRA) que les heures d'absence maladie et de travail correspondant à la période de votre demande sont exactes.",
                              {
                                   autoClose: false,
                              }
                         )
                         helpers.setSubmitting(false)
                         setEditArretInModal(undefined)
                    })
                    .catch(e => {
                         const error: IHttpErrorResponseModel = e.response?.data

                         // Set form errors
                         if (error?.code === CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR && error?.errors) {
                              for (const key in error.errors) helpers.setFieldError(key, error.errors[key])
                         }

                         // Set form global status and notify user using a toast
                         helpers.setStatus(error?.detail)
                         toast.error(error?.detail, { autoClose: false })

                         // Stop submit loader
                         helpers.setSubmitting(false)
                    })
          }

          return (
               editArretInModal && (
                    <MyModal title={<span>Édition de l'arrêt</span>} show={true} handleClose={() => setEditArretInModal(undefined)} size={"lg"}>
                         <Formik initialValues={initialValues} onSubmit={handleSubmit} validationSchema={schema}>
                              {({ values, setFieldValue, isSubmitting, status, errors }) => {
                                   return (
                                        <Form noValidate autoComplete="off">
                                             {status && (
                                                  <MyAlert type={"danger"} classNames={"mb-4"}>
                                                       {status}
                                                  </MyAlert>
                                             )}

                                             <div className="row pb-4">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6 required">Saisissez la période</label>

                                                  <div className="col-lg-8">
                                                       <div className="row">
                                                            <div className="col">
                                                                 <MyDateDayMonthYearField
                                                                      onChange={date => setFieldValue("du", date)}
                                                                      minDate={
                                                                           filtres?.duOrAuWithThisMonthAndYear.startOf("month").format("DD/MM/YYYY") ||
                                                                           filtres?.du.startOf("month").format("DD/MM/YYYY")
                                                                      }
                                                                      maxDate={
                                                                           filtres?.duOrAuWithThisMonthAndYear.endOf("month").format("DD/MM/YYYY") ||
                                                                           filtres?.au.endOf("month").format("DD/MM/YYYY")
                                                                      }
                                                                      value={values.du}
                                                                      name={"du"}
                                                                      placeholder={"Du (inclus)"}
                                                                 />
                                                                 <ErrorMessage name={`du`}>{msg => <div className={"text-danger"}>{msg}</div>}</ErrorMessage>
                                                            </div>
                                                            <div className="col">
                                                                 <MyDateDayMonthYearField
                                                                      onChange={date => setFieldValue("au", date)}
                                                                      value={values.au}
                                                                      minDate={values.du}
                                                                      name={"au"}
                                                                      placeholder={"Au (inclus)"}
                                                                 />
                                                                 <ErrorMessage name={`au`}>{msg => <div className={"text-danger"}>{msg}</div>}</ErrorMessage>
                                                            </div>
                                                       </div>
                                                  </div>
                                             </div>

                                             <div className="row pb-4">
                                                  <label className="col-lg-4 col-form-label fw-bold fs-6">Voulez-vous remplacer le justificatif?</label>

                                                  <div className="col-lg-8 d-flex align-items-center">
                                                       <MyCheckBoxField
                                                            name={"isJustificatifReplaced"}
                                                            value={values.isJustificatifReplaced}
                                                            isInvalid={!!errors.isJustificatifReplaced}
                                                            onChange={val => setFieldValue("isJustificatifReplaced", val)}
                                                       />
                                                       <ErrorMessage name={`isJustificatifReplaced`}>{msg => <div className={"text-danger"}>{msg}</div>}</ErrorMessage>
                                                  </div>
                                             </div>

                                             {values.isJustificatifReplaced && (
                                                  <div className="row pb-4">
                                                       <label className="col-lg-4 col-form-label fw-bold fs-6 required">Justificatif</label>

                                                       <div className="col-lg-8">
                                                            <input
                                                                 type={"file"}
                                                                 name={`justificatif`}
                                                                 onChange={e => {
                                                                      setFieldValue("justificatif", e.target.files![0])
                                                                 }}
                                                                 className={"form-control"}
                                                            />
                                                            <ErrorMessage name={`justificatif`}>{msg => <div className={"text-danger"}>{msg}</div>}</ErrorMessage>
                                                       </div>
                                                  </div>
                                             )}

                                             <div className="text-center pt-4 d-flex flex-row-reverse">
                                                  <button className={"btn btn-sm btn-primary"} type={"submit"} disabled={isSubmitting}>
                                                       {!isSubmitting && <>Appliquer les modifications</>}
                                                       {isSubmitting && (
                                                            <span className="indicator-progress" style={{ display: "block" }}>
                                                                 Edition en cours ...
                                                                 <span className="spinner-border spinner-border-sm align-middle ms-1"></span>
                                                            </span>
                                                       )}
                                                  </button>
                                             </div>
                                        </Form>
                                   )
                              }}
                         </Formik>
                    </MyModal>
               )
          )
     }, [editArretInModal])

     function handleAnnulerArretMaladie(arret: ConsultantArretMaladieModel) {
          MySwal.fire({
               icon: "warning",
               title: `Êtes-vous sûr${currentUser?.candidat?.candidat?.civilite === CONST_CIVILITE_MME ? "e" : ""} de vouloir annuler votre arrêt couvrant du ${arret.du?.format(
                    "DD/MM/YYYY"
               )} au ${arret.au?.format("DD/MM/YYYY")}?`,
               text: "Un mail sera envoyé pour informer de cette annulation.",
               input: "textarea",
               inputPlaceholder: "Précisez la raison de l'annulation",
               inputAttributes: {
                    required: "true",
               },
               showCancelButton: true,
               confirmButtonText: "Oui",
               cancelButtonText: "Annuler",
               showLoaderOnConfirm: true,
               inputValidator: value => {
                    return new Promise((resolve: any) => {
                         value === "" ? resolve("Précisez la raison de l'annulation") : resolve()
                    })
               },
               preConfirm: async (commentaire: string) => {
                    return maladieAnnulerRequest(arret.id as number, commentaire)
                         .then(r => {
                              queryClient.setQueryData("maladieListe", (items: ConsultantArretMaladieModel[] | undefined) => {
                                   if (items) {
                                        return items.filter(item => item.id !== arret.id)
                                   }

                                   return []
                              })
                              toast.success("Votre arrêt a bien été annulé")
                         })
                         .catch(e => {
                              toast.error(e.response.data?.detail, { autoClose: false })
                         })
               },
               allowOutsideClick: () => !MySwal.isLoading(),
          }).then()
     }

     function handleViewJustificatif(e, reference: string, mimeType: string) {
          e.target.disabled = true

          toast.info("Chargement du justificatif en cours ...", { autoClose: false })
          downloadFile(reference, "arraybuffer")
               .then(r => {
                    const url = window.URL.createObjectURL(new Blob([r.data], { type: mimeType }))
                    window.open(url)

                    toast.dismiss()
               })
               .catch((e: AxiosError) => {
                    toast.dismiss()
                    toast.error(e.response?.data?.detail, { autoClose: false })
               })
               .finally(() => {
                    e.target.disabled = false
               })
     }

     return (
          <>
               {editionMode && (
                    <div className={"mb-7 d-flex justify-content-sm-end justify-content-center"}>
                         <button className={`btn btn-light-primary btn-sm me-2`} onClick={() => getMaladieListingQuery.refetch()}>
                              <i className={"fas fa-sync me-md-2 pe-0 fs-3"} />
                              <span className={`d-md-inline d-none`}>Recharger la liste</span>
                         </button>
                         <button className={`btn btn-primary btn-sm`} onClick={() => setIsModalAddDdcShown(true)}>
                              Ajouter mon arrêt <span className={"fas fa-plus-circle fs-2 ms-3 align-middle"} />
                         </button>
                    </div>
               )}

               {/* loader */}
               {getMaladieListingQuery.isFetching && (
                    <div className={"text-center p-4"}>
                         <span className="spinner-border mb-2"></span>
                         <p style={{ fontWeight: 500 }}>Chargement en cours ...</p>
                    </div>
               )}

               {/* error */}
               {!getMaladieListingQuery.isFetching && getMaladieListingQuery.isError && (
                    <MyAlert type={"danger"} classNames={"mb-4"}>
                         <>
                              <span className={"me-2"}>{getMaladieListingQuery.error?.response?.data?.detail}</span>
                              <button className={"btn btn-sm btn-danger"} onClick={() => getMaladieListingQuery.refetch()}>
                                   Recharger
                              </button>
                         </>
                    </MyAlert>
               )}

               {/* no data found */}
               {!getMaladieListingQuery.isFetching && !getMaladieListingQuery.isError && getMaladieListingQuery.data?.length == 0 && (
                    <div className={"text-center p-4"}>
                         <p style={{ fontWeight: 500 }}>Aucun arrêt maladie ...</p>
                    </div>
               )}

               {/* with DDC*/}
               {!getMaladieListingQuery.isFetching && !getMaladieListingQuery.isError && getMaladieListingQuery.data && getMaladieListingQuery.data.length > 0 && (
                    <>
                         <div className="row mb-3" style={{ maxHeight, overflow: "scroll" }}>
                              {getMaladieListingQuery.data.map((item, key) => (
                                   <div className="col-md-6 mb-9" key={key}>
                                        <div className="d-flex align-items-center">
                                             <MyTooltip title={"Consulter le justificatif"}>
                                                  <div className={"w-65px d-flex justify-content-center"}>
                                                       <i
                                                            className={"fas fa-file-medical text-hover-primary cursor-pointer"}
                                                            style={{ fontSize: "4rem" }}
                                                            onClick={e => handleViewJustificatif(e, item.justificatifGoogleDriveID, item.justificatifGoogleDriveMimeType)}
                                                       />
                                                  </div>
                                             </MyTooltip>
                                             <div className={"border-start ps-3 ms-2"}>
                                                  <span className={"text-dark fw-bolder fs-6 d-block py-2"}>Arrêt maladie</span>
                                                  <span className="text-muted d-block fw-bold mb-4">
                                                       <MyTooltip
                                                            title={
                                                                 <span>
                                                                      Du {FirstLetterUppercase(item.du.parseZone().format("dddd D MMMM YYYY"))} au{" "}
                                                                      {FirstLetterUppercase(item.au.parseZone().format("dddd D MMMM YYYY"))}
                                                                 </span>
                                                            }
                                                       >
                                                            <span className="badge badge-light-primary me-1 mb-1">
                                                                 {item.du.parseZone().format("DD/MM/YYYY")} - {item.au.parseZone().format("DD/MM/YYYY")}
                                                            </span>
                                                       </MyTooltip>
                                                  </span>
                                                  {editionMode && (
                                                       <div className="d-flex text-dark fw-bold">
                                                            <span
                                                                 className={"cursor-pointer text-hover-warning d-flex align-items-center me-4"}
                                                                 onClick={() => {
                                                                      setEditArretInModal(item)
                                                                 }}
                                                            >
                                                                 <i className={"fas fa-edit me-2 fs-4 text-warning"} /> Editer
                                                            </span>
                                                            <span
                                                                 className={"cursor-pointer text-hover-danger d-flex align-items-center"}
                                                                 onClick={() => handleAnnulerArretMaladie(item)}
                                                            >
                                                                 <i className={"fas fa-times-circle me-2 fs-4 text-danger"} /> Supprimer
                                                            </span>
                                                       </div>
                                                  )}
                                             </div>
                                        </div>
                                   </div>
                              ))}
                         </div>
                    </>
               )}

               {/* Modals: add, edit & suivi*/}
               {isModalAddDdcShown && FormAddArretMaladieInModal}

               {editArretInModal && FormEditArretMaladieInModal}

               {/*{modalSuivi.isShown && <SuiviInModal/>}*/}
          </>
     )
}

export function MaladieWrapper({ editionMode = true }: { editionMode?: boolean }) {
     return (
          <div className="card">
               <div className="card-body p-5 w-xxl-1200px">
                    <MaladieListe editionMode={editionMode} />
               </div>
          </div>
     )
}
