import { Slide, ToastOptions } from "react-toastify"

export const IS_DEV_ENV_ENABLED: boolean = !process.env.NODE_ENV || process.env.NODE_ENV === "development"

export const CONST_CIVILITE_MR = "CIVILITE_MR"
export const CONST_CIVILITE_MME = "CIVILITE_MME"
export const CONST_CIVILITE_NON_RENSEIGNEE = "CIVILITE_NON_RENSEIGNEE"

export const CONST_CIVILITES_OPTIONS_FOR_SELECT2 = [
     { label: "Monsieur", value: CONST_CIVILITE_MR },
     { label: "Madame", value: CONST_CIVILITE_MME },
     { label: "Non renseignée", value: CONST_CIVILITE_NON_RENSEIGNEE },
]

export const CONST_PAYS_CODE_FRANCE = 250
export const CONST_PAYS_ID_FRANCE = 75

export const CONST_BUSINESS_UNIT_SECRET_DEFENSE_CODE = "DEFENSE"

export const CONST_OUI_NON_FOR_SELECT2 = [
     { label: "Oui", value: true },
     { label: "Non", value: false },
]

export const CONST_DEFAULT_TOAST_OPTIONS: ToastOptions = {
     position: "bottom-right",
     autoClose: 4000,
     hideProgressBar: false,
     closeOnClick: true,
     pauseOnHover: true,
     transition: Slide,
     draggable: true,
     theme: "light",
}

export const REGEXP_PHONE = /^(\d{2} \d{2} \d{2} \d{2} \d{2}|\d{2}\d{2}\d{2}\d{2}\d{2})$/

export const GOOGLE_RECAPTCHA_CLIENT_KEY = process.env.REACT_APP_GOOGLE_RECAPTCHA_CLIENT_KEY as string
export const API_BASE_URL = process.env.REACT_APP_API_URL
export const API_PUBLIC_API_URL = `${API_BASE_URL}/public`
export const API_URL_ESPACE_AVENTURIER = `${API_BASE_URL}/external/app-aventurier`
export const API_URL_ESPACE_CANDIDAT = `${API_URL_ESPACE_AVENTURIER}/espace-candidat`
export const API_URL_ESPACE_CONSULTANT = `${API_URL_ESPACE_AVENTURIER}/espace-consultant`
export const API_URL_ESPACE_COMMON_CANDIDAT_PREEMBAUCHE = `${API_URL_ESPACE_AVENTURIER}/espace-common-candidat-preembauche`
export const API_URL_ESPACE_COMMON_PREEMBAUCHE_CONSULTANT_ST_ENDPOINT = `${API_URL_ESPACE_AVENTURIER}/espace-common-preembauche-consultant-st`

export const API_PUBLIC_GET_PAYS_URL = `${API_PUBLIC_API_URL}/pays`
export const API_PUBLIC_GET_PAYS_INDICATIFS_TEL_URL = `${API_PUBLIC_API_URL}/indicatifs-tel`
export const API_PUBLIC_GET_REGIONS_FRANCE_URL = `${API_PUBLIC_API_URL}/regions-france`
export const API_PUBLIC_GET_DEPARTEMENTS_FRANCE_URL = `${API_PUBLIC_API_URL}/departements-france`
export const API_PUBLIC_GET_VILLES_FRANCE_URL = `${API_PUBLIC_API_URL}/villes-france`
export const API_PUBLIC_GET_SECTEURS_ACTIVITE_URL = `${API_PUBLIC_API_URL}/secteurs-activite`
export const API_PUBLIC_GET_CANDIDAT_DETAILS_URL = id => `${API_PUBLIC_API_URL}/candidats/${id}/details`
export const API_PUBLIC_GET_ANNONCE_DETAILS_URL = reference => `${API_PUBLIC_API_URL}/annonces/${reference}/details`

export const API_GET_PAYS_URL = `${API_URL_ESPACE_AVENTURIER}/utils/get-pays`
export const API_GET_PAYS_INDICATIFS_TEL_URL = `${API_URL_ESPACE_AVENTURIER}/utils/get-pays-indicatifs-tel`
export const API_GET_REGIONS_FRANCE_URL = `${API_URL_ESPACE_AVENTURIER}/utils/get-regions-france`
export const API_GET_DEPARTEMENTS_FRANCE_URL = `${API_URL_ESPACE_AVENTURIER}/utils/get-departements-france`
export const API_GET_VILLES_FRANCE_URL = `${API_URL_ESPACE_AVENTURIER}/utils/get-villes-france`
export const CONST_API_UTILS_GET_GOOGLE_MAPS_ADDRESS_AUTOCOMPLETION_ENDPOINT = API_URL_ESPACE_AVENTURIER + "/utils/get-google-maps-address-autocompletion"
export const CONST_API_UTILS_GET_GOOGLE_MAPS_DISTANCE_BETWEEN_TWO_POINTS_IN_KM_ENDPOINT = API_URL_ESPACE_AVENTURIER + "/utils/get-google-maps-distance-between-two-points-in-km"

export const API_GET_REFERENCES_MISSION_CONSULTANT_FOR_GIVEN_MONTH = `${API_URL_ESPACE_CONSULTANT}/utils/get-references-mission-consultant-for-given-month`
export const API_GET_GOOGLE_DRIVE_IDS_DOCUMENTS_EMBAUCHE_ENDPOINT = `${API_URL_ESPACE_CONSULTANT}/get-documents-embauche-google-drive-ids`

export const API_FOURNISSEUR_SET_INFO_FOURNISSEUR_BY_FOURNISSEUR_ENDPOINT = `${API_URL_ESPACE_COMMON_PREEMBAUCHE_CONSULTANT_ST_ENDPOINT}/set-info-fournisseur`
export const API_FOURNISSEUR_SET_DOCUMENT_BY_FOURNISSEUR_ENDPOINT = `${API_URL_ESPACE_COMMON_PREEMBAUCHE_CONSULTANT_ST_ENDPOINT}/set-document-fournisseur`
export const API_FOURNISSEUR_DELETE_DOCUMENT_BY_FOURNISSEUR_ENDPOINT = `${API_URL_ESPACE_COMMON_PREEMBAUCHE_CONSULTANT_ST_ENDPOINT}/delete-document-fournisseur`

const API_ESPACE_FOURNISSEUR_RESP_ENDPOINT = `${API_URL_ESPACE_AVENTURIER}/fournisseur-resp`
export const API_ESPACE_FOURNISSEUR_RESP_GET_COLLABORATEURS_ENDPOINT = `${API_ESPACE_FOURNISSEUR_RESP_ENDPOINT}/collaborateurs`
export const API_ESPACE_FOURNISSEUR_RESP_GET_RESP_FOURNISSEUR_ENDPOINT = `${API_ESPACE_FOURNISSEUR_RESP_ENDPOINT}/get-responsables-fournisseur`
export const API_ESPACE_FOURNISSEUR_RESP_SET_RESP_FOURNISSEUR_COLLAB_ENDPOINT = `${API_ESPACE_FOURNISSEUR_RESP_ENDPOINT}/set-responsable-fournisseur-collab`
export const API_ESPACE_FOURNISSEUR_RESP_GET_COLLABORATEUR_DETAILS_ENDPOINT = (id: any) => `${API_ESPACE_FOURNISSEUR_RESP_GET_COLLABORATEURS_ENDPOINT}/${id}/details`
export const API_ESPACE_FOURNISSEUR_RESP_GET_DECLARATIONS_VOYANTS_ENDPOINT = `${API_ESPACE_FOURNISSEUR_RESP_ENDPOINT}/declarations/voyants`
export const API_ESPACE_FOURNISSEUR_RESP_GET_DECLARATIONS_LISTING_ENDPOINT = `${API_ESPACE_FOURNISSEUR_RESP_ENDPOINT}/declarations/listing`
export const API_ESPACE_FOURNISSEUR_RESP_SET_DETAILS_COLLAB_BY_RESP_FOURNISSEUR_ENDPOINT = (id: number) =>
     `${API_ESPACE_FOURNISSEUR_RESP_ENDPOINT}/collaborateurs/${id}/set-details-by-resp-fournisseur`

export const CONST_USER_ROLE_CANDIDAT = "ROLE_CANDIDAT"
export const CONST_USER_ROLE_CANDIDAT_EN_COURS_EMBAUCHE = "ROLE_CANDIDAT_EN_COURS_EMBAUCHE"
export const CONST_USER_ROLE_CONSULTANT = "ROLE_CONSULTANT"
export const CONST_USER_ROLE_RESPONSABLE_FOURNISSEUR = "ROLE_RESPONSABLE_FOURNISSEUR"
export const CONST_USER_ROLE_RESPONSABLE_FOURNISSEUR_ADMIN = "ROLE_RESPONSABLE_FOURNISSEUR_ADMIN"
export const CONST_USER_ROLE_SUPER_ADMIN = "ROLE_SUPER_ADMIN"

/* ENTITY Fournisseur Document*/
export const CONST_FOURNISSEUR_DOCUMENT_EXTRAIT_KBIS = "TYPE_FOURNISSEUR_EXTRAIT_KBIS"
export const CONST_FOURNISSEUR_DOCUMENT_ASSURANCE_RC_PRO = "TYPE_FOURNISSEUR_ASSURANCE_RC_PRO"
export const CONST_FOURNISSEUR_DOCUMENT_VIGILANCE_URSSAF = "TYPE_FOURNISSEUR_VIGILANCE_URSSAF"
export const CONST_FOURNISSEUR_DOCUMENT_REGULARITE_FISCALE = "TYPE_FOURNISSEUR_REGULARITE_FISCALE"
export const CONST_FOURNISSEUR_DOCUMENT_RIB = "TYPE_FOURNISSEUR_RIB"

export const CONST_CANDIDAT_DOCUMENT_TYPE_CV = "TYPE_CV"

export const CONST_CANDIDAT_DOCUMENT_TYPES_FOR_SELECT2 = [{ label: "Curriculum", value: CONST_CANDIDAT_DOCUMENT_TYPE_CV }]

export const CONST_CONSULTANT_ETAT_EN_COURS_EMBAUCHE_POUR_MISSION = "ETAT_EN_COURS_EMBAUCHE_POUR_MISSION"
export const CONST_CONSULTANT_ETAT_EN_COURS_EMBAUCHE_SANS_MISSION = "ETAT_EN_COURS_EMBAUCHE_SANS_MISSION"
export const CONST_CONSULTANT_ETAT_EMBAUCHE_AVEC_MISSION = "ETAT_EMBAUCHE_AVEC_MISSION"
export const CONST_CONSULTANT_ETAT_EMBAUCHE_INACTIF = "ETAT_EMBAUCHE_INACTIF"
export const CONST_CONSULTANT_ETAT_HORS_EFFECTIF = "ETAT_HORS_EFFECTIF"
export const CONST_CONSULTANT_ETAT_EMBAUCHE_SANS_MISSION = "ETAT_EMBAUCHE_SANS_MISSION"

/* ENTITY ConsultantDeclarationMensuelleSuivi */
export const CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_INTERESSE_FINALISATION = "TYPE_INTERESSE_FINALISATION"
export const CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_INTERESSE_SIGNATURE = "TYPE_INTERESSE_SIGNATURE"
export const CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_INTERESSE_RECTIFICATION_SANS_DEMANDE = "TYPE_INTERESSE_RECTIFICATION_SANS_DEMANDE"
export const CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_RESP_DEMANDE_RECTIFICATION = "TYPE_RESP_DEMANDE_RECTIFICATION"
export const CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_RESP_VALIDATION = "TYPE_RESP_VALIDATION"
export const CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDE_RECTIFICATION = "TYPE_SIEGE_DEMANDE_RECTIFICATION"
export const CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_SIEGE_VALIDATION = "TYPE_SIEGE_VALIDATION"
export const CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_INTERESSE_DEMANDE_REGUL = "TYPE_INTERESSE_DEMANDE_REGUL"
export const CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_RESP_VALIDATION_REGUL = "TYPE_RESP_VALIDATION_REGUL"
export const CONST_CONSULTANT_DECLARATION_SUIVI_TYPE_RESP_REJET_REGUL = "TYPE_RESP_REJET_REGUL"

export const CONST_CONSULTANT_STATUT_ETAM = "STATUT_ETAM"
export const CONST_CONSULTANT_STATUT_AGENT_MAITRISE = "STATUT_AGENT_MAITRISE"
export const CONST_CONSULTANT_STATUT_CADRE = "STATUT_CADRE"
export const CONST_CONSULTANT_STATUT_ST_PORTAGE = "STATUT_ST_PORTAGE"
export const CONST_CONSULTANT_STATUT_ST_SOCIETE_SERVICE = "STATUT_ST_SOCIETE_SERVICE"
export const CONST_CONSULTANT_STATUT_ST_FREELANCE = "STATUT_ST_FREELANCE"

export const CONST_CONSULTANT_CONTRAT_CDI = "CONTRAT_CDI"
export const CONST_CONSULTANT_CONTRAT_CDD = "CONTRAT_CDD"
export const CONST_CONSULTANT_CONTRAT_CDIC = "CONTRAT_CDIC"
export const CONST_CONSULTANT_CONTRAT_ST = "CONTRAT_ST"

export const CONST_CONSULTANT_DOCUMENT_TYPE_PASSEPORT_CNI = "TYPE_PASSEPORT_CNI"
export const CONST_CONSULTANT_DOCUMENT_TYPE_TITRE_SEJOUR = "TYPE_TITRE_SEJOUR"
export const CONST_CONSULTANT_DOCUMENT_TYPE_ATTESTATION_SS = "TYPE_ATTESTATION_SS"
export const CONST_CONSULTANT_DOCUMENT_TYPE_RIB = "TYPE_RIB"
export const CONST_CONSULTANT_DOCUMENT_TYPE_CONTRAT = "TYPE_CONTRAT"
export const CONST_CONSULTANT_DOCUMENT_TYPE_AVENANT_CONTRAT = "TYPE_AVENANT_CONTRAT"
export const CONST_CONSULTANT_DOCUMENT_TYPE_PERMIS_CONDUIRE = "TYPE_PERMIS_CONDUIRE"
export const CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME = "TYPE_DIPLOME"
export const CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES = "TYPE_AUTRES"

export const CONST_CONSULTANT_DOCUMENT_TYPES_FOR_SELECT2 = [
     { label: "Passport / CNI", value: CONST_CONSULTANT_DOCUMENT_TYPE_PASSEPORT_CNI },
     { label: "Titre de séjour", value: CONST_CONSULTANT_DOCUMENT_TYPE_TITRE_SEJOUR },
     { label: "Attestation SS", value: CONST_CONSULTANT_DOCUMENT_TYPE_ATTESTATION_SS },
     { label: "RIB", value: CONST_CONSULTANT_DOCUMENT_TYPE_RIB },
     { label: "Permis de conduire", value: CONST_CONSULTANT_DOCUMENT_TYPE_PERMIS_CONDUIRE },
     { label: "Diplôme", value: CONST_CONSULTANT_DOCUMENT_TYPE_DIPLOME },
     { label: "Autre", value: CONST_CONSULTANT_DOCUMENT_TYPE_AUTRES },
]

export const CONST_CANDIDAT_DISPONIBILITE_DATE_EXACTE = "DISPONIBILITE_DATE_EXACTE"
export const CONST_CANDIDAT_MOBILITE_DEPARTEMENTAL = "MOBILITE_DEPARTEMENTAL"
export const CONST_CANDIDAT_MOBILITE_REGIONAL = "MOBILITE_REGIONAL"
export const CONST_CANDIDAT_MOBILITE_PAR_VILLE = "MOBILITE_PAR_VILLE"

export const CONST_NIVEAU_ETUDES_TECHNICIEN = "NIVEAU_ETUDES_TECHNICIEN"
export const CONST_NIVEAU_ETUDES_INGE = "NIVEAU_ETUDES_INGE"

export const CONST_NIVEAU_ETUDES_OPTIONS_FOR_SELECT2 = [
     { label: "Technicien", value: CONST_NIVEAU_ETUDES_TECHNICIEN },
     { label: "Ingénieur", value: CONST_NIVEAU_ETUDES_INGE },
]

export const CONST_DISPONIBILITE_IMMEDIATE = "DISPONIBILITE_IMMEDIATE"
export const CONST_DISPONIBILITE_PREAVIS_EN_COURS = "DISPONIBILITE_PREAVIS_EN_COURS"
export const CONST_DISPONIBILITE_PREAVIS_2_SEMAINES = "DISPONIBILITE_PREAVIS_2_SEMAINES"
export const CONST_DISPONIBILITE_PREAVIS_1_MOIS = "DISPONIBILITE_PREAVIS_1_MOIS"
export const CONST_DISPONIBILITE_PREAVIS_2_MOIS = "DISPONIBILITE_PREAVIS_2_MOIS"
export const CONST_DISPONIBILITE_PREAVIS_3_MOIS = "DISPONIBILITE_PREAVIS_3_MOIS"
export const CONST_DISPONIBILITE_DATE_EXACTE = "DISPONIBILITE_DATE_EXACTE"

export const CONST_DISPONIBILITE_OPTIONS_FOR_SELECT2 = [
     { label: "Immédiate", value: CONST_DISPONIBILITE_IMMEDIATE },
     { label: "Préavis en cours", value: CONST_DISPONIBILITE_PREAVIS_EN_COURS },
     { label: "Préavis de 2 semaines", value: CONST_DISPONIBILITE_PREAVIS_2_SEMAINES },
     { label: "Préavis de 1 mois", value: CONST_DISPONIBILITE_PREAVIS_1_MOIS },
     { label: "Préavis de 2 mois", value: CONST_DISPONIBILITE_PREAVIS_2_MOIS },
     { label: "Préavis de 3 mois", value: CONST_DISPONIBILITE_PREAVIS_3_MOIS },
     { label: "Date exacte", value: CONST_DISPONIBILITE_DATE_EXACTE },
]

export const CONST_MOBILITE_FRANCE = "MOBILITE_FRANCE"
export const CONST_MOBILITE_FRANCE_HORS_IDF = "MOBILITE_FRANCE_HORS_IDF"
export const CONST_MOBILITE_IDF = "MOBILITE_IDF"
export const CONST_MOBILITE_DEPARTEMENTAL = "MOBILITE_DEPARTEMENTAL"
export const CONST_MOBILITE_REGIONAL = "MOBILITE_REGIONAL"
export const CONST_MOBILITE_PAR_VILLE = "MOBILITE_PAR_VILLE"
export const CONST_MOBILITE_INTERNATIONAL = "MOBILITE_INTERNATIONAL"

export const CONST_MOBILITE_OPTIONS_FOR_SELECT2 = [
     {
          label: "Par ville",
          value: CONST_MOBILITE_PAR_VILLE,
     },
     {
          label: "Départemental",
          value: CONST_MOBILITE_DEPARTEMENTAL,
     },
     {
          label: "Régional",
          value: CONST_MOBILITE_REGIONAL,
     },
     {
          label: "Île de France",
          value: CONST_MOBILITE_IDF,
     },
     {
          label: "Province",
          value: CONST_MOBILITE_FRANCE_HORS_IDF,
     },
     {
          label: "International",
          value: CONST_MOBILITE_INTERNATIONAL,
     },
     {
          label: "France",
          value: CONST_MOBILITE_FRANCE,
     },
]

export const CONST_DECLARATION_SUIVI_TYPE_INTERESSE_FINALISATION = "TYPE_INTERESSE_FINALISATION"
export const CONST_DECLARATION_SUIVI_TYPE_INTERESSE_SIGNATURE = "TYPE_INTERESSE_SIGNATURE"
export const CONST_DECLARATION_SUIVI_TYPE_INTERESSE_RECTIFICATION_SANS_DEMANDE = "TYPE_INTERESSE_RECTIFICATION_SANS_DEMANDE"
export const CONST_DECLARATION_SUIVI_TYPE_RESP_DEMANDE_RECTIFICATION = "TYPE_RESP_DEMANDE_RECTIFICATION"
export const CONST_DECLARATION_SUIVI_TYPE_RESP_VALIDATION = "TYPE_RESP_VALIDATION"
export const CONST_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDE_RECTIFICATION = "TYPE_SIEGE_DEMANDE_RECTIFICATION"
export const CONST_DECLARATION_SUIVI_TYPE_SIEGE_VALIDATION = "TYPE_SIEGE_VALIDATION"
export const CONST_DECLARATION_SUIVI_TYPE_INTERESSE_DEMANDE_REGUL = "TYPE_INTERESSE_DEMANDE_REGUL"
export const CONST_DECLARATION_SUIVI_TYPE_RESP_VALIDATION_REGUL = "TYPE_RESP_VALIDATION_REGUL"
export const CONST_DECLARATION_SUIVI_TYPE_RESP_REJET_REGUL = "TYPE_RESP_REJET_REGUL"

export const CONST_DDC_SUIVI_TYPE_ENVOI_DEMANDE = "TYPE_ENVOI_DEMANDE"
export const CONST_DDC_SUIVI_TYPE_ACCEPTATION_DEMANDE = "TYPE_ACCEPTATION_DEMANDE"
export const CONST_DDC_SUIVI_TYPE_RECTIFICATION_PAR_CONSULTANT_AVANT_DECISION_BM = "TYPE_RECTIFICATION_PAR_CONSULTANT_AVANT_DECISION_BM"
export const CONST_DDC_SUIVI_TYPE_REJET_DEMANDE = "TYPE_REJET_DEMANDE"
export const CONST_DDC_SUIVI_TYPE_ANNULATION_DEMANDE = "TYPE_ANNULATION_DEMANDE"

export const CONST_DDC_TYPE_CONGES_PAYES = "TYPE_CONGES_PAYES"
export const CONST_DDC_TYPE_CONGES_SANS_SOLDE = "TYPE_CONGES_SANS_SOLDE"
export const CONST_DDC_TYPE_REPOS_COMPENSATEUR = "TYPE_REPOS_COMPENSATEUR"
export const CONST_DDC_TYPE_ABSENCE_AUTORISEE = "TYPE_ABSENCE_AUTORISEE"
export const CONST_DDC_TYPE_ABSENCE_EXCEPTIONNELLE = "TYPE_ABSENCE_EXCEPTIONNELLE"
export const CONST_DDC_TYPE_ABSENCE_JOUR_NON_TRAVAILLE_FORFAIT = "TYPE_ABSENCE_JOUR_NON_TRAVAILLE_FORFAIT"
export const CONST_DDC_TYPE_ABSENCE_RTT = "ABSENCE_RTT"

export const CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_MARIAGE = "SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_MARIAGE"
export const CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_PACS = "SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_PACS"
export const CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_MARIAGE_ENFANT = "SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_MARIAGE_ENFANT"
export const CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_NAISSANCE_ENFANT = "SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_NAISSANCE_ENFANT"
export const CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_ENFANT_MOINS_25_ANS = "SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_ENFANT_MOINS_25_ANS"
export const CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_ENFANT_PLUS_25_ANS = "SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_ENFANT_PLUS_25_ANS"
export const CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_CONJOINT_CONCUBUN_PARTENAIRE_PACS = "SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_CONJOINT_CONCUBUN_PARTENAIRE_PACS"
export const CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_PARENT = "SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_PARENT"
export const CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_PARENTS_CONJOINT_LIE_PAR_MARIAGE = "SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_PARENTS_CONJOINT_LIE_PAR_MARIAGE"
export const CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_FRERE_SOEUR = "SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_FRERE_SOEUR"
export const CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_BEAU_PERE_BELLE_MERE_DU_CONJOINT_MARIE_PACS =
     "SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_BEAU_PERE_BELLE_MERE_DU_CONJOINT_MARIE_PACS"
export const CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_ANNONCE_HANDICAP_OU_MALADIE_GRAVE_ENFANT = "SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_ANNONCE_HANDICAP_OU_MALADIE_GRAVE_ENFANT"
export const CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_GRAND_PARENT_OU_ARRIERE_GRAND_PARENT = "SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_GRAND_PARENT_OU_ARRIERE_GRAND_PARENT"
export const CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_AUTRE = "SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_AUTRE"

export const CONST_DDC_TYPES_FOR_SELECT2 = [
     { label: "Congés payés", value: CONST_DDC_TYPE_CONGES_PAYES },
     { label: "Congés sans solde", value: CONST_DDC_TYPE_CONGES_SANS_SOLDE },
     { label: "Repos compensateur", value: CONST_DDC_TYPE_REPOS_COMPENSATEUR },
     { label: "Absence autorisée", value: CONST_DDC_TYPE_ABSENCE_AUTORISEE },
     { label: "Absence exceptionnelle", value: CONST_DDC_TYPE_ABSENCE_EXCEPTIONNELLE },
     { label: "Jour non travaillé", value: CONST_DDC_TYPE_ABSENCE_JOUR_NON_TRAVAILLE_FORFAIT },
     { label: "RTT", value: CONST_DDC_TYPE_ABSENCE_RTT },
]

export const CONST_DDC_SOUS_TYPES_FOR_SELECT2 = [
     { label: "Mariage", value: CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_MARIAGE },
     { label: "PACS", value: CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_PACS },
     { label: "Mariage d'un enfant", value: CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_MARIAGE_ENFANT },
     { label: "Naissance enfant", value: CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_NAISSANCE_ENFANT },
     { label: "Décès d'un enfant de moins de 25 ans", value: CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_ENFANT_MOINS_25_ANS },
     { label: "Décès d'un enfant de plus de 25 ans", value: CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_ENFANT_PLUS_25_ANS },
     { label: "Décès du conjoint ou concubin ou partenaire de PACS", value: CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_CONJOINT_CONCUBUN_PARTENAIRE_PACS },
     { label: "Décès parents", value: CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_PARENT },
     { label: "Décès des parents du conjoint lié par le mariage", value: CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_PARENTS_CONJOINT_LIE_PAR_MARIAGE },
     { label: "Décès frère/soeur", value: CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_FRERE_SOEUR },
     { label: "Décès beau-père/belle-mère (du conjoint marié ou pacsé)", value: CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_BEAU_PERE_BELLE_MERE_DU_CONJOINT_MARIE_PACS },
     { label: "Annonce de la survenue d'un handicap ou maladie grave chez un enfant", value: CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_ANNONCE_HANDICAP_OU_MALADIE_GRAVE_ENFANT },
     { label: "Décès grands-parents/ arrière-grands-parents", value: CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_DECES_GRAND_PARENT_OU_ARRIERE_GRAND_PARENT },
     { label: "Autre", value: CONST_SOUS_TYPE_ABSENCE_EXCEPTIONNELLE_AUTRE },
]

export const CONST_CONSULTANT_MISSION_REFERENCE_FORFAIT_GENERIQUE_POUR_CRA = "FORFAIT"

export const CONST_CRA_DETAILS_ABSCENCE_CPA = CONST_DDC_TYPE_CONGES_PAYES
export const CONST_CRA_DETAILS_ABSCENCE_CSS = CONST_DDC_TYPE_CONGES_SANS_SOLDE
export const CONST_CRA_DETAILS_ABSCENCE_JOURS_NON_TRAVAILLES_FORFAIT = CONST_DDC_TYPE_ABSENCE_JOUR_NON_TRAVAILLE_FORFAIT
export const CONST_CRA_DETAILS_ABSCENCE_CEX = CONST_DDC_TYPE_ABSENCE_EXCEPTIONNELLE
export const CONST_CRA_DETAILS_ABSCENCE_CMA = "ABSCENCE_CMA"
export const CONST_CRA_DETAILS_ABSCENCE_FERIES = "ABSCENCE_FERIES"
export const CONST_CRA_DETAILS_ABSCENCE_REPOS_COMPENSATEUR = CONST_DDC_TYPE_REPOS_COMPENSATEUR
export const CONST_CRA_DETAILS_ABSCENCE_SFP = "ABSCENCE_SFP"
export const CONST_CRA_DETAILS_ABSCENCE_RTT = CONST_DDC_TYPE_ABSENCE_RTT
export const CONST_CRA_DETAILS_ABSCENCE_AUTORISEE = CONST_DDC_TYPE_ABSENCE_AUTORISEE

export const CONST_ENTITE_CODE_AIRFRAME = "CODE_AIRFRAME"
export const CONST_ENTITE_CODE_AIRSYS = "CODE_AIRSYS"
export const CONST_ENTITE_CODE_CTS = "CODE_CTS"
export const CONST_ENTITE_CODE_GS = "CODE_GS"
export const CONST_ENTITE_CODE_IT = "CODE_IT"
export const CONST_ENTITE_CODE_NATESYS = "CODE_NATESYS"
export const CONST_ENTITE_CODE_NEO = "CODE_NEO"
export const CONST_ENTITE_CODE_NORD = "CODE_NORD"
export const CONST_ENTITE_CODE_SENSE = "CODE_SENSE"

export const CONST_NDF_DETAILS_CATEGORIE_KM = "CATEGORIE_KM"
export const CONST_NDF_DETAILS_CATEGORIE_CARBURANT = "CATEGORIE_CARBURANT"
export const CONST_NDF_DETAILS_CATEGORIE_PEAGE = "CATEGORIE_PEAGE"
export const CONST_NDF_DETAILS_CATEGORIE_PARKING = "CATEGORIE_PARKING"
export const CONST_NDF_DETAILS_CATEGORIE_AVION = "CATEGORIE_AVION"
export const CONST_NDF_DETAILS_CATEGORIE_TRAIN = "CATEGORIE_TRAIN"
export const CONST_NDF_DETAILS_CATEGORIE_TAXI = "CATEGORIE_TAXI"
export const CONST_NDF_DETAILS_CATEGORIE_TRANSPORT_EN_COMMUN = "CATEGORIE_TRANSPORT_EN_COMMUN"
export const CONST_NDF_DETAILS_CATEGORIE_HOTEL = "CATEGORIE_HOTEL"
export const CONST_NDF_DETAILS_CATEGORIE_REPAS_MIDI = "CATEGORIE_REPAS_MIDI"
export const CONST_NDF_DETAILS_CATEGORIE_REPAS_SOIR = "CATEGORIE_REPAS_SOIR"
export const CONST_NDF_DETAILS_CATEGORIE_DIVERS = "CATEGORIE_DIVERS"
export const CONST_NDF_DETAILS_CATEGORIE_FORFAIT_GRAND_DEPLACEMENT = "CATEGORIE_FORFAIT_GRAND_DEPLACEMENT"

export const CONST_NDF_DETAILS_CATEGORIES_OPTIONS_FOR_SELECT2 = [
     {
          value: CONST_NDF_DETAILS_CATEGORIE_KM,
          label: "indemnités kilométriques",
     },
     {
          value: CONST_NDF_DETAILS_CATEGORIE_CARBURANT,
          label: "Carburant",
     },
     {
          value: CONST_NDF_DETAILS_CATEGORIE_PEAGE,
          label: "Péage",
     },
     {
          value: CONST_NDF_DETAILS_CATEGORIE_PARKING,
          label: "Parking",
     },
     {
          value: CONST_NDF_DETAILS_CATEGORIE_AVION,
          label: "Avion",
     },
     {
          value: CONST_NDF_DETAILS_CATEGORIE_TRANSPORT_EN_COMMUN,
          label: "Transport en commun",
     },
     {
          value: CONST_NDF_DETAILS_CATEGORIE_TRAIN,
          label: "Train",
     },
     {
          value: CONST_NDF_DETAILS_CATEGORIE_TAXI,
          label: "Taxi",
     },
     {
          value: CONST_NDF_DETAILS_CATEGORIE_HOTEL,
          label: "Hôtel",
     },
     {
          value: CONST_NDF_DETAILS_CATEGORIE_REPAS_MIDI,
          label: "Repas midi",
     },
     {
          value: CONST_NDF_DETAILS_CATEGORIE_REPAS_SOIR,
          label: "Repas soir",
     },
     {
          value: CONST_NDF_DETAILS_CATEGORIE_FORFAIT_GRAND_DEPLACEMENT,
          label: "Forfait grand déplacement",
     },
     {
          value: CONST_NDF_DETAILS_CATEGORIE_DIVERS,
          label: "Divers",
     },
]

export const CONST_MIME_APPLICATION_JSON = "application/json"
export const CONST_MIME_APPLICATION_XML = "application/xml"
export const CONST_MIME_APPLICATION_PDF = "application/pdf"
export const CONST_MIME_APPLICATION_DOCX = "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
export const CONST_MIME_APPLICATION_DOC = "application/msword"
export const CONST_MIME_APPLICATION_GOOGLE_DOC = "application/vnd.google-apps.document"
export const CONST_MIME_APPLICATION_ODT = "application/vnd.oasis.opendocument.text"
export const CONST_MIME_APPLICATION_XLS = "application/vnd.ms-excel"
export const CONST_MIME_APPLICATION_XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
export const CONST_MIME_APPLICATION_ODS = "application/vnd.oasis.opendocument.spreadsheet"
export const CONST_MIME_APPLICATION_GOOGLE_SPREADSHEET = "application/vnd.google-apps.spreadsheet"
export const CONST_MIME_APPLICATION_PPT = "application/vnd.ms-powerpoint"
export const CONST_MIME_APPLICATION_PPTX = "application/vnd.openxmlformats-officedocument.presentationml.presentation"
export const CONST_MIME_APPLICATION_ODP = "application/vnd.oasis.opendocument.presentation"
export const CONST_MIME_APPLICATION_GOOGLE_PRESENTATION = "application/vnd.google-apps.presentation"
export const CONST_MIME_IMAGE_JPEG = "image/jpeg"
export const CONST_MIME_IMAGE_JPG = "image/jpg"
export const CONST_MIME_IMAGE_PNG = "image/png"

export const CONST_MOIS_JANVIER = "01"
export const CONST_MOIS_FEVRIER = "02"
export const CONST_MOIS_MARS = "03"
export const CONST_MOIS_AVRIL = "04"
export const CONST_MOIS_MAI = "05"
export const CONST_MOIS_JUIN = "06"
export const CONST_MOIS_JUILLET = "07"
export const CONST_MOIS_AOUT = "08"
export const CONST_MOIS_SEPTEMBRE = "09"
export const CONST_MOIS_OCTOBRE = "10"
export const CONST_MOIS_NOVEMBRE = "11"
export const CONST_MOIS_DECEMBRE = "12"

export const CONST_MOIS_LIST = [
     { label: "Janvier", value: CONST_MOIS_JANVIER },
     { label: "Fevrier", value: CONST_MOIS_FEVRIER },
     { label: "Mars", value: CONST_MOIS_MARS },
     { label: "Avril", value: CONST_MOIS_AVRIL },
     { label: "Mai", value: CONST_MOIS_MAI },
     { label: "Juin", value: CONST_MOIS_JUIN },
     { label: "Juillet", value: CONST_MOIS_JUILLET },
     { label: "Aout", value: CONST_MOIS_AOUT },
     { label: "Septembre", value: CONST_MOIS_SEPTEMBRE },
     { label: "Octobre", value: CONST_MOIS_OCTOBRE },
     { label: "Novembre", value: CONST_MOIS_NOVEMBRE },
     { label: "Decembre", value: CONST_MOIS_DECEMBRE },
]

export const CONST_HTTP_CUSTOM_CODE_FORM_VALIDATION_ERROR = "FORM_VALIDATION_ERROR"
