import { Link } from "react-router-dom"
import React, { useContext, useEffect, useState } from "react"
import { IListingItemResponseModel, IListingResponseModel } from "../core/models"
import useDeclarationsList from "../core/hooks"
import { DeclarationsListContext } from "./DeclarationsList"
import { toast } from "react-toastify"
import {
     declarationAddFactureFraisFastWayForStPortageOuSS,
     declarationAddFacturePrestationFastWayForStPortageOuSS,
     declarationSetNbJoursTravaillesParStPortageOuSS,
} from "../../../../app-consultant/modules/activites/core/_request"
import { useQueryClient } from "react-query"
import { useConsultantDeclarationActions } from "../../../../app-consultant/modules/activites/components/declaration/core/_hooks"
import {
     API_URL_ESPACE_AVENTURIER,
     CONST_DECLARATION_SUIVI_TYPE_INTERESSE_DEMANDE_REGUL,
     CONST_DECLARATION_SUIVI_TYPE_INTERESSE_RECTIFICATION_SANS_DEMANDE,
     CONST_DECLARATION_SUIVI_TYPE_INTERESSE_SIGNATURE,
     CONST_DECLARATION_SUIVI_TYPE_RESP_DEMANDE_RECTIFICATION,
     CONST_DECLARATION_SUIVI_TYPE_RESP_REJET_REGUL,
     CONST_DECLARATION_SUIVI_TYPE_RESP_VALIDATION,
     CONST_DECLARATION_SUIVI_TYPE_RESP_VALIDATION_REGUL,
     CONST_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDE_RECTIFICATION,
     CONST_DECLARATION_SUIVI_TYPE_SIEGE_VALIDATION,
} from "../../../../constants"
import MyTooltip from "../../../../utils/MyTooltip"
import MySimpleSpinner from "../../../../utils/MySimpleSpinner"
import axios, { AxiosError } from "axios"
import MyLoadingOverlay from "../../../../utils/MyLoadingOverlay"

const Item = ({ item }: { item: IListingItemResponseModel }) => {
     // Hooks
     const queryClient = useQueryClient()
     const { setSelectedItemForDeclaration, mois, refreshRows } = useContext(DeclarationsListContext)
     const { EtatBadge } = useDeclarationsList()
     const { actions, mutations } = useConsultantDeclarationActions()

     // States
     const [nbJoursTravailles, setNbJoursTravailles] = useState<string>(item.nbJoursTravailles === 0 ? "" : item.nbJoursTravailles.toString())
     const [isDraggingCell5, setIsDraggingCell5] = useState(false)
     const [isDraggingCell6, setIsDraggingCell6] = useState(false)
     const [isNbJoursInputBegingEdited, setIsNbJoursInputBegingEdited] = useState(false)

     // Vars
     const canEditDeclaration = (() => {
          return (
               !item.declaration ||
               !!(
                    item.declaration &&
                    (!item.declaration.etat?.type ||
                         (item.declaration.etat?.type &&
                              [
                                   CONST_DECLARATION_SUIVI_TYPE_INTERESSE_RECTIFICATION_SANS_DEMANDE,
                                   CONST_DECLARATION_SUIVI_TYPE_RESP_DEMANDE_RECTIFICATION,
                                   CONST_DECLARATION_SUIVI_TYPE_RESP_VALIDATION_REGUL,
                              ].includes(item.declaration.etat?.type)))
               )
          )
     })()

     function handleEntamerRectificationSansValidationRespOuSiege() {
          actions.entamerRectificationSansDemandeValidation(item.declaration?.id as number, () => {
               refreshRows([item.consultant.id!]).then(() => {
                    setSelectedItemForDeclaration({ item, defaultStep: "recap" })
               })
          })
     }

     function handleDemandeRegulCallback() {
          actions.demandeRegul(item?.declaration?.id!, () => refreshRows([item.consultant.id!]))
     }

     // Handlers for facture presta drag & drop
     function handleViewDoc(e: any, reference: string, mimeType: string) {
          e.target.disabled = true

          toast.info("Chargement du document en cours ...", { autoClose: false })
          axios.post(
               `${API_URL_ESPACE_AVENTURIER}/download-document`,
               { reference },
               {
                    responseType: "arraybuffer",
               }
          )
               .then(r => {
                    const url = window.URL.createObjectURL(new Blob([r.data], { type: mimeType }))
                    window.open(url)

                    toast.dismiss()
               })
               .catch((e: AxiosError) => {
                    toast.dismiss()
                    toast.error(e.response?.data?.detail, { autoClose: false })
               })
               .finally(() => {
                    e.target.disabled = false
               })
     }

     const handleDragEnterCellFacturesPrestation = (e: React.DragEvent<HTMLTableCellElement>) => {
          e.preventDefault()
          setIsDraggingCell5(true)
     }

     const handleDragLeaveFacturesPrestation = (e: React.DragEvent<HTMLTableCellElement>) => {
          e.preventDefault()
          setIsDraggingCell5(false)
     }

     const handleDropFacturesPrestation = async (e: React.DragEvent<HTMLTableCellElement>) => {
          e.preventDefault()
          setIsDraggingCell5(false)
          // Get the file from the drop
          const file = e.dataTransfer.files[0]

          toast.info("Upload en cours ...", { autoClose: false })

          try {
               await declarationAddFacturePrestationFastWayForStPortageOuSS(item.consultant.id!, mois.format("MM")!, mois.format("YYYY")!, file)
               toast.dismiss()
               toast.success("La facture de prestation a bien été déposée")
               refreshRows([item.consultant.id!]).then()
          } catch (e: any) {
               toast.error(e.response.data?.detail, { autoClose: false })
          }
     }

     const handleDragOverFacturesPrestation = (e: React.DragEvent<HTMLTableCellElement>) => {
          e.preventDefault()
     }

     // Handlers for the facture frais drag & drop
     const handleDragEnterFacturesFrais = (e: React.DragEvent<HTMLTableCellElement>) => {
          e.preventDefault()
          setIsDraggingCell6(true)
     }

     const handleDragLeaveFacturesFrais = (e: React.DragEvent<HTMLTableCellElement>) => {
          e.preventDefault()
          setIsDraggingCell6(false)
     }

     const handleDropFacturesFrais = async (e: React.DragEvent<HTMLTableCellElement>) => {
          e.preventDefault()
          setIsDraggingCell6(false)

          // Get the file from the drop
          const file = e.dataTransfer.files[0]

          toast.info("Upload en cours ...", { autoClose: false })

          try {
               await declarationAddFactureFraisFastWayForStPortageOuSS(item.consultant.id!, mois.format("MM")!, mois.format("YYYY")!, file)
               toast.dismiss()
               toast.success("Le document a bien été déposé")
               refreshRows([item.consultant.id!]).then()
          } catch (e: any) {
               toast.error(e.response.data?.detail, { autoClose: false })
          }
     }

     const handleDragOverFacturesFrais = (e: React.DragEvent<HTMLTableCellElement>) => {
          e.preventDefault()
     }

     function handleSetNbJoursTravailles(e: React.ChangeEvent<HTMLInputElement>) {
          // Allow digits, commas, and periods
          const value = e.target.value.replace(",", ".") // Replace comma with dot
          if (/^[0-9]*[.,]?[0-9]*$/.test(value)) {
               // Matches numbers with optional decimal
               setNbJoursTravailles(value)
          }
     }

     function handleConfirmSetNbJoursTravailles(value: number) {
          setIsNbJoursInputBegingEdited(true)
          declarationSetNbJoursTravaillesParStPortageOuSS(item.consultant.id!, mois.format("MM"), mois.format("YYYY"), Number(value)).then(() => {
               refreshRows([item.consultant.id!]).then(() => {
                    queryClient.setQueryData<IListingResponseModel | undefined>("QUERY_LISTING", (prev: IListingResponseModel | undefined) => {
                         if (prev) {
                              const index = prev.data.findIndex(item_ => item_.consultant.id === item?.consultant.id)
                              if (index >= 0) {
                                   prev.data[index] = {
                                        ...prev.data[index],
                                        nbJoursTravailles: Number(value),
                                   }
                                   return prev
                              }
                         }

                         return prev
                    })
                    setIsNbJoursInputBegingEdited(false)
               })
          })
     }

     async function handleEnvoiDeclaration() {
          try {
               await actions.validationRespFournisseur(item.declaration?.id!, async () => {
                    await refreshRows([item.declaration?.consultant?.id!])
                    toast.success("Déclaration envoyée")
               })
          } catch (e: any) {
               toast.error(e.response.data?.detail, { autoClose: false })
               return
          }
     }

     useEffect(() => {
          setNbJoursTravailles(item.nbJoursTravailles === 0 ? "" : item.nbJoursTravailles.toString())
     }, [item.nbJoursTravailles])

     const ActionsButtonsAccordingToEtat = () => {
          // Si pas de déclaration
          if (!item.declaration) {
               const title =
                    Number(nbJoursTravailles) === item.nbJoursTravailles ? (
                         <div>Merci de joindre une facture de prestation et saisir les nombre de jours travaillés avant d'envoyer cette déclaration</div>
                    ) : (
                         <div>Merci de valider votre saisie dans le champ "Jours travaillés" avant d'envoyer cette déclaration</div>
                    )
               return (
                    <MyTooltip title={title}>
                         <button
                              className={`btn btn-secondary btn-sm d-flex align-items-center`}
                              onClick={() => {
                                   toast.info(title)
                              }}
                         >
                              Envoyer <span className={"fas fa-paper-plane fs-4 ms-1"} />
                         </button>
                    </MyTooltip>
               )
          }

          // Si déclaration mais pas d'état
          if (item.declaration.etat === null) {
               return (
                    <div className={"d-flex gap-2"}>
                         <MyTooltip title={"Apporter une modification"}>
                              <button type={"button"} className={"btn btn-sm btn-warning"} onClick={() => setSelectedItemForDeclaration({ item, defaultStep: "recap" })}>
                                   Editer <span className={"fas fa-edit align-middle fs-4"} />
                              </button>
                         </MyTooltip>
                         <MyTooltip
                              title={
                                   !(item.facturesDePrestation.length > 0 && item.nbJoursTravailles > 0) ? (
                                        <div>Merci de joindre une facture de prestation et saisir les nombre de jours travaillés avant d'envoyer cette déclaration</div>
                                   ) : (
                                        "Finaliser et envoyer la déclaration"
                                   )
                              }
                         >
                              <button
                                   className={`btn btn-${
                                        item.facturesDePrestation.length > 0 && item.nbJoursTravailles > 0 ? "success" : "secondary"
                                   } btn-sm d-flex align-items-center`}
                                   onClick={() => {
                                        if (item.facturesDePrestation.length > 0 && item.nbJoursTravailles > 0) {
                                             handleEnvoiDeclaration().then()
                                        } else {
                                             toast.info("Merci de joindre une facture de prestation et saisir les nombre de jours travaillés avant d'envoyer cette déclaration")
                                        }
                                   }}
                              >
                                   {mutations.validationRespFournisseur.isLoading ? (
                                        <MySimpleSpinner size={"sm"} />
                                   ) : (
                                        <>
                                             Envoyer <span className={"fas fa-paper-plane fs-4 ms-1"} />
                                        </>
                                   )}
                              </button>
                         </MyTooltip>
                    </div>
               )
          }

          // Si entamer rectif sans demande de validation
          if ([CONST_DECLARATION_SUIVI_TYPE_INTERESSE_RECTIFICATION_SANS_DEMANDE].includes(item.declaration?.etat?.type!)) {
               return (
                    <div className={"d-flex gap-2"}>
                         <button
                              type={"button"}
                              className={`btn btn-warning btn-sm px-3 py-2 d-flex align-items-center`}
                              onClick={() => setSelectedItemForDeclaration({ item, defaultStep: "recap" })}
                         >
                              Éditer <span className={`fas fa-edit ms-1 fs-4`} />
                         </button>
                         <MyTooltip
                              title={
                                   !(item.facturesDePrestation.length > 0 && item.nbJoursTravailles > 0) ? (
                                        <div>Merci de joindre une facture de prestation et saisir les nombre de jours travaillés avant d'envoyer cette déclaration</div>
                                   ) : (
                                        "Finaliser et envoyer la déclaration"
                                   )
                              }
                         >
                              <button
                                   className={`btn btn-${
                                        item.facturesDePrestation.length > 0 && item.nbJoursTravailles > 0 ? "success" : "secondary"
                                   } btn-sm d-flex align-items-center`}
                                   onClick={() => {
                                        if (item.facturesDePrestation.length > 0 && item.nbJoursTravailles > 0) {
                                             handleEnvoiDeclaration().then()
                                        } else {
                                             toast.info("Merci de joindre une facture de prestation et saisir les nombre de jours travaillés avant d'envoyer cette déclaration")
                                        }
                                   }}
                              >
                                   {mutations.validationRespFournisseur.isLoading ? (
                                        <MySimpleSpinner size={"sm"} />
                                   ) : (
                                        <>
                                             Envoyer <span className={"fas fa-paper-plane fs-4 ms-1"} />
                                        </>
                                   )}
                              </button>
                         </MyTooltip>
                    </div>
               )
          }

          // Etat signé par resp fournisseur
          if ([CONST_DECLARATION_SUIVI_TYPE_INTERESSE_SIGNATURE].includes(item.declaration.etat?.type!)) {
               return (
                    <div className={"d-flex gap-2"}>
                         <MyTooltip title={"Apporter une modification"}>
                              <button type={"button"} className={"btn btn-sm btn-warning"} onClick={handleEntamerRectificationSansValidationRespOuSiege}>
                                   Rectifier <span className={"fas fa-edit align-middle fs-4"} />
                              </button>
                         </MyTooltip>

                         <MyTooltip title={"Consulter"}>
                              <button type={"button"} className={"btn btn-sm btn-primary"} onClick={() => setSelectedItemForDeclaration({ item, defaultStep: "recap" })}>
                                   Consulter <span className={"fas fa-eye align-middle fs-4"} />
                              </button>
                         </MyTooltip>
                    </div>
               )
          }

          if ([CONST_DECLARATION_SUIVI_TYPE_RESP_DEMANDE_RECTIFICATION, CONST_DECLARATION_SUIVI_TYPE_RESP_VALIDATION_REGUL].includes(item.declaration.etat?.type!)) {
               return (
                    <div className={"d-flex gap-2"}>
                         <button
                              type={"button"}
                              className={`btn btn-danger btn-sm px-3 py-2 d-flex align-items-center`}
                              onClick={() => setSelectedItemForDeclaration({ item, defaultStep: "recap" })}
                         >
                              Corriger <span className={`fas fa-edit ms-1 fs-4`} />
                         </button>
                    </div>
               )
          }

          if ([CONST_DECLARATION_SUIVI_TYPE_RESP_VALIDATION].includes(item.declaration.etat?.type!)) {
               return (
                    <MyTooltip title={"Consulter"}>
                         <button type={"button"} className={"btn btn-sm btn-primary"} onClick={() => setSelectedItemForDeclaration({ item, defaultStep: "recap" })}>
                              Consulter <span className={"fas fa-eye align-middle fs-4"} />
                         </button>
                    </MyTooltip>
               )
          }

          if (
               [
                    CONST_DECLARATION_SUIVI_TYPE_INTERESSE_DEMANDE_REGUL,
                    CONST_DECLARATION_SUIVI_TYPE_RESP_VALIDATION,
                    CONST_DECLARATION_SUIVI_TYPE_SIEGE_DEMANDE_RECTIFICATION,
               ].includes(item.declaration.etat?.type!)
          ) {
               return (
                    <button type={"button"} className={"btn btn-sm btn-primary"} onClick={() => setSelectedItemForDeclaration({ item, defaultStep: "recap" })}>
                         Consulter <i className={"fas fa-eye align-middle fs-4"} />
                    </button>
               )
          }

          if ([CONST_DECLARATION_SUIVI_TYPE_SIEGE_VALIDATION, CONST_DECLARATION_SUIVI_TYPE_RESP_REJET_REGUL].includes(item.declaration.etat?.type!)) {
               return (
                    <div className={"d-flex gap-2"}>
                         <MyTooltip title={"Consulter"}>
                              <button type={"button"} className={"btn btn-sm btn-primary"} onClick={() => setSelectedItemForDeclaration({ item, defaultStep: "recap" })}>
                                   Consulter <span className={"fas fa-eye align-middle fs-4"} />
                              </button>
                         </MyTooltip>
                         <MyTooltip title={"Demander une régularisation"}>
                              <button type={"button"} className={"btn btn-sm btn-danger me-2"} onClick={handleDemandeRegulCallback}>
                                   Régulariser <span className={"fas fa-hand align-middle fs-4"} />
                              </button>
                         </MyTooltip>
                    </div>
               )
          }

          return <>NADA</>
     }

     return (
          <tr className="align-middle">
               <td>
                    <Link to={`/espace-resp-fournisseur/collaborateurs/${item.consultant.id}/details`}>{item.consultant.prenomNom}</Link>
               </td>
               <td className={"text-center"}>{item.consultant.sousTraitant?.fournisseurResponsable?.prenomNom}</td>
               <td className={"text-center"}>
                    <EtatBadge declaration={item.declaration} />
               </td>
               <td>
                    <div className={`d-flex justify-content-center gap-3`}>
                         <MyLoadingOverlay isActive={isNbJoursInputBegingEdited} size={"sm"} styles={{ width: "10rem" }}>
                              <input
                                   value={nbJoursTravailles}
                                   placeholder={"À renseigner"}
                                   type={"text"}
                                   onChange={handleSetNbJoursTravailles}
                                   onKeyDown={e => {
                                        if (e.key === "Enter" && Number(nbJoursTravailles) !== item.nbJoursTravailles) {
                                             handleConfirmSetNbJoursTravailles(Number(nbJoursTravailles))
                                        }
                                   }}
                                   className={"text-center"}
                                   style={{ width: "10rem" }}
                                   disabled={!canEditDeclaration}
                              />
                         </MyLoadingOverlay>
                         {Number(nbJoursTravailles) !== item.nbJoursTravailles && (
                              <button
                                   type={"button"}
                                   className={"btn btn-outline btn-outline-success d-flex align-items-center justify-content-center px-2"}
                                   style={{ height: "2rem", width: "2rem" }}
                                   onClick={() => handleConfirmSetNbJoursTravailles(Number(nbJoursTravailles))}
                              >
                                   <span className={"fas fa-check-circle"} />
                              </button>
                         )}
                    </div>
               </td>
               <td
                    className={`w-200px text-center ${isDraggingCell6 ? "bg-secondary" : ""}`}
                    onDragEnter={handleDragEnterFacturesFrais}
                    onDragLeave={handleDragLeaveFacturesFrais}
                    onDrop={handleDropFacturesFrais}
                    onDragOver={handleDragOverFacturesFrais}
               >
                    {item.facturesDeFrais.length > 0 ? (
                         <div className="d-flex gap-2 justify-content-center">
                              {item.facturesDeFrais.map((item, key) => (
                                   <div key={key}>
                                        <span
                                             className={"fas fa-file-invoice-dollar text-primary fs-4 cursor-pointer p-2"}
                                             onClick={e => handleViewDoc(e, item.googleDriveID as string, item.googleDriveMimeType as string)}
                                        />
                                   </div>
                              ))}
                         </div>
                    ) : (
                         <div className="text-center text-muted cursor-default">
                              Glisser vos documents <span className={"fas fa-cloud-arrow-up text-center fs-3"} />
                         </div>
                    )}
               </td>
               <td
                    className={`w-200px text-center ${isDraggingCell5 ? "bg-secondary" : ""}`}
                    onDragEnter={handleDragEnterCellFacturesPrestation}
                    onDragLeave={handleDragLeaveFacturesPrestation}
                    onDrop={handleDropFacturesPrestation}
                    onDragOver={handleDragOverFacturesPrestation}
               >
                    {item.facturesDePrestation.length > 0 ? (
                         <div className="d-flex gap-2 justify-content-center">
                              {item.facturesDePrestation.map((item, key) => (
                                   <div key={key}>
                                        <span
                                             className={"fas fa-file-invoice-dollar text-danger fs-4 cursor-pointer p-2"}
                                             onClick={e => handleViewDoc(e, item.googleDriveId as string, item.googleDriveMimeType as string)}
                                        />
                                   </div>
                              ))}
                         </div>
                    ) : (
                         <div className="text-center text-muted cursor-default">
                              Glisser vos factures <span className={"fas fa-cloud-arrow-up text-center fs-3"} />
                         </div>
                    )}
               </td>

               <td>
                    <ActionsButtonsAccordingToEtat />
               </td>
          </tr>
     )
}

export default Item
